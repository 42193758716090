<template>
  <v-container class="pa-0" fluid style="height: 100vh">
    <v-overlay opacity="0.95" :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto relative">
        <notification-message
          :message.sync="errorMessage"
          :type="errorMessage && error ? 'error' : 'success'"
        />
        <v-container class="d-flex align-center justify-center h-100">
          <v-card width="440" flat class="animate__animated animate__zoomIn">
            <v-col>
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img :src="logoSnappy" width="45" height="45" />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <div v-if="from === 'register'">
                <h3 class="font-semibold">Registration Successful!</h3>
                <h5 class="font-weight-regular my-2 text-base">
                  A verification email has been sent to <b>{{ email }}</b>
                </h5>
                <ul class="text-base">
                  <li>Verify within 30 minutes using the link.</li>
                  <li>
                    Check your
                    <b>Inbox, Spam, Junk, or Promotions tab (Gmail users)</b>
                  </li>
                </ul>
              </div>

              <h5 v-else class="font-weight-regular my-2">
                Your Email is not Verified, Click the Resend Verification Button
                and Check your email to verify
              </h5>
              <p v-if="from == 'register'" class="text-subtitle-1 mt-8">
                I didn't see the email?
                <a class="primary--text font-weight-bold" @click="resendEmail"
                  >Resend</a
                >
              </p>
              <v-btn v-else class="mt-5" color="primary" @click="resendEmail"
                >Resend
              </v-btn>
              <div class="d-flex justify-end">
                <router-link to="/login" class="mt-5 grey--text"
                  >Back to login
                </router-link>
              </div>
            </v-col>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logoSnappy from '../../assets/logo.png'
import api from '../../api/users/auth'
import notificationMessage from '../../components/notification/notificationMessage.vue'

export default {
  components: { notificationMessage },
  data() {
    return {
      errorMessage: null,
      logoSnappy,
      error: false,
      code: '',
      loading: false,
      token: '',
      email: '',
      from: ''
    }
  },
  methods: {
    async resendEmail() {
      this.loading = true
      const res = await api.auth().sendVerificationEmail(this.email)
      if (res.error) {
        // this.errorMessage = res.errorMessage.message
        // this.error = true
        this.$swal.fire({
          icon: 'error',
          title: 'Oh, an error occurred',
          text: res.errorMessage.message
        })
        this.loading = false
        return
      }

      this.$swal.fire({
        icon: 'success',
        title: 'Sent!',
        text: 'Email verification mail has been re-sent to you'
      })

      this.errorMessage = null
      this.error = false
      this.loading = false
    }
  },
  created() {
    this.email = this.$route.query.email
    this.from = this.$route.query.from
  }
}
</script>

<style></style>
